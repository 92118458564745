<template>
  <div>
    <div class="download-banner"></div>
    <div class="download-main">
      <div class="download-first">
        <div>
          <div class="download-phone"></div>
          <div class="download-now">
            <h3>老马爱家（雇主用户端）</h3>
            <em>为需要家政服务及产品的用户提供下单服务</em>
            <h6>立即下载</h6>
            <span>Android、iOS极速下载！</span>
            <div class="download-now-cont">
              
              <div class="download-Android">
                <img src="https://laomaaijia.com/img/download/employer.png" alt="">
                <div class="imgs" @click="upload(0)">
                  <img style="width: 27px;height: 30px" src="https://laomaaijia.com/img/download/Android.png" alt="">
                  <span>直接下载</span>
              </div>
              </div>
              <div class="download-iPhone">
                <div>
                  <div class="text">请在您的苹果手机的AppStore 中搜索“老马爱家”并下载安装</div>
                </div>
                <img class="imgs" style="width: 27px;height: 30px" src="https://laomaaijia.com/img/download/iPhone.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="download-second">
        <div>
          <div class="download-now">
            <h3>老马服务（家政服务端）</h3>
            <em>为家政服务从业人员提供接单管理</em>
            <h6>立即下载</h6>
            <span>Android、iOS极速下载！</span>
            <div class="download-now-cont">
              <div class="download-Android">

                <img src="https://laomaaijia.com/img/download/service.png" alt="">
                <div class="imgs" @click="upload(1)">
                    <img style="width: 27px;height: 30px" src="https://laomaaijia.com/img/download/Android.png" alt="">
                    <span>直接下载</span>
                </div>
                
              </div>
              <div class="download-iPhone">
                <div>
                  <div class="text">请在您的苹果手机的AppStore 中搜索“老马服务”并下载安装</div>
                </div>
                <img class="imgs" style="width: 27px;height: 30px" src="https://laomaaijia.com/img/download/iPhone.png" alt="">
              </div>
              
            </div>
          </div>
          <div class="download-phone"></div>
        </div>
      </div>
      <div class="download-third">
        <div>
          <div class="download-phone"></div>
          <div class="download-now">
            <h3>老马商家（家政商家端）</h3>
            <em>为家政服务个体、企业及机构提供管理平台</em>
            <h6>立即下载</h6>
            <span>Android、iOS极速下载！</span>
            <div class="download-now-cont">
              <div class="download-Android">
                <img src="https://laomaaijia.com/img/download/business.png" alt="" >
                <div class="imgs" @click="upload(2)">
                  <img style="width: 27px;height: 30px" src="https://laomaaijia.com/img/download/Android.png" alt="">
                  <span>直接下载</span>
              </div>
              </div>
              <div class="download-iPhone">
                <div>
                  <div class="text">请在您的苹果手机的AppStore 中搜索“老马商家”并下载安装</div>
                </div>
                <img class="imgs" style="width: 27px;height: 30px" src="https://laomaaijia.com/img/download/iPhone.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      employerAndroidQR : false,
      employeriOSQR : false,
      serviceAndroidQR : false,
      serviceiOSQR : false,
      businessAndroidQR : false,
      businessiOSQR : false,
    }
  },
  methods: {
    upload(e) {
      switch(e) {
        case 0:
          //老马爱家
          // window.open('https://espier-files-dingdongdong.oss-cn-chengdu.aliyuncs.com/package/1/2022/06/28/2e4ec48a981ec2db71025a5ab9f62be52KpXvneDmY3H4Ymv130hWWyixeD35Pvk')
          window.open('https://b.laomaaijia.com/api/website/app/download?port_type=1&type=1')
          break;
        case 1:
          //老马服务
          // window.open('https://espier-files-dingdongdong.oss-cn-chengdu.aliyuncs.com/package/1/2022/07/01/94218c1eebf4e9314ea8271f474a3b8cei1I4zFLEyQuC5qyqbRswNTWyQhihlGs')
          window.open('https://b.laomaaijia.com/api/website/app/download?port_type=2&type=1')
          break;
        case 2:
          //老马商家
          // window.open('https://espier-files-dingdongdong.oss-cn-chengdu.aliyuncs.com/package/1/2022/07/01/76ff1a943dcb21ee4006b7601c70c8bePiGQYAnOgVLQPic2HjUfs781LNROHkEE')
          window.open('https://b.laomaaijia.com/api/website/app/download?port_type=3&type=1')
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../style/download.less";
</style>
